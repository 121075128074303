var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.whitelabel !== 'OrbitInvest')?_c('div',{staticClass:"m-3 mt-5"},[(_vm.whitelabel !== 'GCCBrokers')?_c('div',{staticClass:"flex-col max-w-screen-xl mx-auto py-10 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"},[(_vm.whitelabel === 'EagleGlobalMarkets')?_c('a',{staticClass:"self-start"},[_c('img',{staticClass:"md:block h-auto w-32",attrs:{"src":require("../assets/EagleGlobalMarkets-logo.svg"),"alt":"logo"}})]):_vm._e(),_c('span',{staticClass:"mt-1 text-sm leading-5 text-gray-500",class:_vm.whitelabel === 'TD365' ? '' : 'text-center'},[(
          _vm.whitelabel === 'TD365' ||
          (_vm.whitelabel === 'TDSouthAfrica' && this.$route.path.includes('ib'))
        )?_c('span',{staticClass:"font-bold"},[_vm._v(" Disclaimer: ")]):(_vm.whitelabel === 'Blackstone')?_c('span',{staticClass:"font-bold"},[_vm._v(" High Risk Investment Warning: ")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('risk-warning'))}}),(
          _vm.whitelabel === 'TD365' ||
          _vm.whitelabel === 'TDSouthAfrica' ||
          _vm.whitelabel === 'Blackstone'
        )?_c('span',{staticClass:"ml-1",domProps:{"innerHTML":_vm._s(
          _vm.$t(_vm.show_footer_text).replace(/%(\d+)/g, function (_, n) { return _vm.url[+n - 1]; })
        )}}):_c('span',{staticClass:"ml-1 footer-text-container",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.show_footer_text))}})]),(_vm.whitelabel === 'ICMCapital')?_c('div',[_c('br'),_c('span',{staticClass:"mt-1 text-center text-sm leading-5 text-gray-500"},[_vm._v(" ICM Capital Limited, Level 17, Dashwood House, 69 Old Broad Street, London EC2M 1QS, United Kingdom ")])]):_vm._e()]):_vm._e(),(_vm.whitelabel === 'GCCBrokers')?_c('div',{staticClass:"flex-col max-w-screen-xl mx-auto py-10 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"},[_c('span',{staticClass:"mt-1 text-center text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t( "gcc-brokers-limited-is-regulated-by-the-financial-services-commission-mauritius-under-registration-number" ))+" ")])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }