<template>
  <div class="main-layout-vue">
    <Navigation
      :show-user-menu="true"
      :show-white-logo="true"
      class="hide"
    ></Navigation>
    <div class="flex">
      <Sidebar />
      <div :class="checkLanguage" class="w-screen items-center">
        <!-- Start of icmsupport Zendesk Widget script -->
        <script
          v-if="
            (whitelabel === 'ICMVC' || whitelabel === 'ICMMU') &&
            showIcmZendeskSnippet === 'version1'
          "
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=73872fca-8778-4236-b4d9-3ebdb45e021a"
          type="application/javascript"
        ></script>
        <!-- End of icmsupport Zendesk Widget script -->

        <script
          v-if="
            (whitelabel === 'ICMVC' || whitelabel === 'ICMMU') &&
            showIcmZendeskSnippet === 'version2'
          "
          type="application/javascript"
        >
          window.__lc = window.__lc || {};
          window.__lc.license = 10385472;
          (function (n, t, c) {
            function i(n) {
              return e._h ? e._h.apply(null, n) : e._q.push(n);
            }

            var e = {
              _q: [],
              _h: null,
              _v: "2.0",
              on: function () {
                i(["on", c.call(arguments)]);
              },
              once: function () {
                i(["once", c.call(arguments)]);
              },
              off: function () {
                i(["off", c.call(arguments)]);
              },
              get: function () {
                if (!e._h)
                  throw new Error(
                    "[LiveChatWidget] You can't use getters before load.",
                  );
                return i(["get", c.call(arguments)]);
              },
              call: function () {
                i(["call", c.call(arguments)]);
              },
              init: function () {
                var n = t.createElement("script");
                (n.async = !0),
                  (n.type = "text/javascript"),
                  (n.src = "https://cdn.livechatinc.com/tracking.js"),
                  t.head.appendChild(n);
              },
            };
            !n.__lc.asyncInit && e.init(),
              (n.LiveChatWidget = n.LiveChatWidget || e);
          })(window, document, [].slice);
        </script>
        <noscript
          v-if="
            (whitelabel === 'ICMVC' || whitelabel === 'ICMMU') &&
            showIcmZendeskSnippet === 'version2'
          "
          ><a
            v-if="
              (whitelabel === 'ICMVC' || whitelabel === 'ICMMU') &&
              showIcmZendeskSnippet === 'version2'
            "
            href="https://www.livechat.com/chat-with/10385472/"
            rel="nofollow"
            >Chat with us</a
          >, powered by
          <a
            v-if="
              (whitelabel === 'ICMVC' || whitelabel === 'ICMMU') &&
              showIcmZendeskSnippet === 'version2'
            "
            href="https://www.livechat.com/?welcome"
            rel="noopener nofollow"
            target="_blank"
            >LiveChat</a
          >
        </noscript>

        <div>
          <router-view id="router-view" class="flex-1" />
          <AnnualCheckModal v-if="this.$store.getters.get_annual_check" />
          <Footer class="mx-auto" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import Sidebar from "@/components/Sidebar";
import Footer from "@/components/Footer";
import AnnualCheckModal from "@/components/AnnualCheckModal";
import { mapGetters } from "vuex";
import AlertNav from "@/components/AlertNav.vue";

export default {
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      zendeskVersion: "",
      intervalId: null,
    };
  },
  components: {
    AlertNav,
    Navigation,
    Sidebar,
    Footer,
    AnnualCheckModal,
  },
  computed: {
    ...mapGetters(["get_icm_zendesk_version"]),
    checkLanguage() {
      let lang = this.$store.getters.get_lang;
      if (lang == "ar" || lang == "fa") {
        return "main-layout-wrapper-ar";
      } else {
        return "main-layout-wrapper";
      }
    },
    showIcmZendeskSnippet() {
      return (this.zendeskVersion =
        this.$store.getters.get_icm_zendesk_version);
    },
  },
  created() {
    this.$store.dispatch("profile").catch((error) => {
      console.error("Error dispatching 'profile': ", error);
    });
    this.$store.dispatch("account_data").catch((error) => {
      console.error("Error dispatching 'account_data': ", error);
    });
    this.$store.dispatch("get_backends").catch((error) => {
      console.error("Error dispatching 'get_backends': ", error);
    });
    this.$store.dispatch("user_data").catch((error) => {
      console.error("Error dispatching 'user_data': ", error);
    });

    this.fetchProfileData();

    // Initialize the interval and save intervalId to the component instance
    this.intervalId = setInterval(() => this.fetchProfileData(), 10000); // Fetch user data every 10 seconds
  },

  methods: {
    fetchProfileData() {
      this.$store
        .dispatch("profile")
        .then((res) => {
          const profile = this.$store.getters["get_profile"];
          const state = profile.state;

          console.log("Profile state is: ", state, "Checking is in progress");
          this.$store.dispatch("account_data").catch((error) => {
            console.error("Error dispatching 'account_data': ", error);
          });

          if (state === "Full") {
            clearInterval(this.intervalId);
            const checkWhenLiveAccountCreated = profile.trading_account?.find(
              (account) => account.Type === "Live",
            )?.created;
            const isLessThenMinute =
              new Date() - new Date(checkWhenLiveAccountCreated) < 60000;
            if (isLessThenMinute) {
              this.$notify({
                group: "foo",
                text: "Registration Complete, Live Account Added!",
                type: "success",
              });
            }

            console.log("Checking is done, current state is: ", state);
          }
        })
        .catch((error) => {
          console.error("Error in 'fetchProfileData': ", error);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style>
.hide {
  display: none;
}

@media (max-width: 1023px) {
  .hide {
    display: block;
  }
}
</style>
